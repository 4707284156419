export default function sendMessageToExtension(message) {
  if (typeof message !== 'object' || message.constructor !== Object || Object.keys(message).length === 0) {
    throw new Error('DemoBlox > sendMessage > Missing required Object: message')
  } else if (typeof message.action != 'string') {
    throw new Error('DemoBlox > sendMessage > Missing required String: message.action')
  }

  return new Promise(function (resolve, reject) {
    // eslint-disable-next-line no-undef
    if (typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined') {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, message, null, (response) => {
        if (response) {
          if (response.status === 'success') {
            resolve(true)
          } else {
            resolve(response)
          }
        } else {
          reject(Error('DemoBlox > No response: chrome.runtime.sendMessage'))
        }
      })
    }
  })
}
