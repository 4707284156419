import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ScenesNav() {
  const { pathname } = useLocation()

  const tabs = useMemo(
    () => [
      { name: 'Allow Pages', href: 'allow-pages', current: pathname.includes('allow-pages') },
      { name: 'Block Actions', href: 'block-actions', current: pathname.includes('block-actions') },
      { name: 'Auto Fills', href: 'auto-fills', current: pathname.includes('auto-fills') },
    ],
    [pathname]
  )

  return (
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav className="flex justify-center -mb-px" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}
