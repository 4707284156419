import { XIcon} from '@heroicons/react/outline'

export default function DeleteIcon({ handleClick }) {
  return (
    <button
      type="button"
      className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-400 hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={handleClick}
    >
      <XIcon className="h-3 w-3" aria-hidden="true" />
    </button>
  )
}
