async function fetchRoles(accessToken) {
  const result = await fetch(`/api/v1/roles`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

export const RoleService = {
  fetchRoles,
}
