import { useLocation, useNavigate } from 'react-router-dom'

export default function UserManagementHeader() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isCreate = !!pathname.match(/create/g)

  const handleAddUserClick = () => {
    navigate('create')
  }

  const handleBackClick = () => {
    navigate('/admin/users')
  }

  const createButtonJSX = (
    <button
      type="button"
      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={handleAddUserClick}
    >
      Add New User
    </button>
  )

  const backButtonJSX = (
    <button
      type="button"
      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={handleBackClick}
    >
      Back to Users
    </button>
  )

  return (
    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{pathname === '/admin/users/create' ? 'Create New User' : 'Users Management'}</h3>
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-4">{isCreate ? backButtonJSX : createButtonJSX}</div>
    </div>
  )
}
