async function fetchUsers(accessToken) {
  const result = await fetch(`/api/v1/users`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function createUser(accessToken, payload) {
  const result = await fetch(`/api/v1/users`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    method: 'POST',
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function updateUser(accessToken, payload, userId) {
  const result = await fetch(`/api/v1/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    method: 'PUT',
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

export const UserService = {
  fetchUsers,
  createUser,
  updateUser,
}
