import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import UserManagementHeader from '../atoms/UserManagementHeader'
import UserSelection from '../atoms/UserSelection'

export default function Users() {
  const { pathname } = useLocation()

  const [selectedUser, setSelectedUser] = useState()

  // Clear selected user when adding a new user
  useEffect(() => {
    if (!!pathname.match(/create/g)) setSelectedUser(undefined)
  }, [pathname])

  return (
    <div>
      <UserManagementHeader />
      {(pathname === '/admin/users' || !!pathname.match('/admin/users/edit')) && (
        <UserSelection setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
      )}
      <Outlet context={[selectedUser]} />
    </div>
  )
}
