export function addHoursToDate(intHours, max) {
  const date = new Date()
  const numberOfMlSeconds = date.getTime()
  const addMlSeconds = max ? 24 * 365 * 60 * 60 * 1000 : intHours * 60 * 60 * 1000
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds)

  return newDateObj
}

export function getCookieJS(cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function setCookieJS(cname, cvalue, expTime) {
  const expDate = new Date(expTime)
  document.cookie = `${cname}=${cvalue};expires=${expDate};path=/`
}
