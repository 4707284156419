import { Field } from 'formik'

export default function CastTypeFormInput({ errors, touched, handleChange, values, index, setFieldValue }) {
  // const errorState = errors.castTypes[index] && touched.castTypes[index] && 'border-red-600'
  const handleUpdates = (ev) => {
    setFieldValue(`castTypes.${index}.name`, ev.target.value)
    setFieldValue(`castTypes.${index}.id`, index + 1)
  }

  return (
    <>
      <div className="col-span-6 sm:col-span-6">
        <label htmlFor={`castTypes.${index}.name`} className="block text-sm font-medium text-gray-700">
          <span className="text-lg text-indigo-500">{index + 1}.</span> Name
        </label>
        <Field
          type="text"
          name={`castTypes.${index}.name`}
          onChange={handleUpdates}
          placeholder=""
          className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${''}`}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <label htmlFor={`castTypes.${index}.key`} className="block text-sm font-medium text-gray-700">
          Key
        </label>
        <Field
          type="text"
          name={`castTypes.${index}.key`}
          onChange={handleChange}
          value={values.key ?? ''}
          placeholder=""
          className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${''}`}
        />
      </div>
    </>
  )
}
