import sendMessageToExtension from '../common/helpers/sendMessagetoExtension'

async function createUserCast(accessToken, payload) {
  const result = await fetch(`/api/v1/casts`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })

  if (result.ok) {
    const jsonResponse = await result.json()

    return jsonResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchCasts(hashEmail, accessToken) {
  const result = await fetch(`/api/v1/casts?userHashEmail=${hashEmail}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchCast(accessToken, id) {
  const result = await fetch(`/api/v1/casts/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function updateUserCastName(userHashEmail, accessToken, castId, name) {
  const result = await fetch(`/api/v1/casts/${castId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({ name, userHashEmail }),
  })

  if (result.ok) {
    const jsonResponse = await result.json()

    return jsonResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function updateUserCastValues(userHashEmail, accessToken, castId, values) {
  const result = await fetch(`/api/v1/casts/${castId}/values`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({ values, userHashEmail }),
  })

  if (result.ok) {
    const jsonResponse = await result.json()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return jsonResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function activateCast(accessToken, castId, groupId) {
  const result = await fetch(`/api/v1/casts/${castId}/activate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ groupId }),
  })

  if (result.ok) {
    const textResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return textResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function deactivateCast(accessToken, castId, groupId) {
  const result = await fetch(`/api/v1/casts/${castId}/deactivate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ groupId }),
  })

  if (result.ok) {
    const textResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return textResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function deleteUserCast(accessToken, castId) {
  sendMessageToExtension({
    action: 'clearDemoData',
  })
  try {
    return await fetch(`/api/v1/casts/${castId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'DELETE',
    })
  } catch (error) {
    return error
  }
}

export const CastService = {
  createUserCast,
  fetchCasts,
  deleteUserCast,
  updateUserCastName,
  updateUserCastValues,
  activateCast,
  deactivateCast,
  fetchCast,
}
