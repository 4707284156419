import { useContext, useEffect } from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { NotificationContext } from '../../context/NotificationContext'
import { UserContext } from '../../context/UserContext'
import { CastGroupService } from '../../services/CastGroupService'
import { CastService } from '../../services/CastService'
import { CastTypeService } from '../../services/CastTypeService'
import ActiveIcon from './ActiveIcon'
import { SunIcon } from '@heroicons/react/outline'

export default function CastTypeItem({ type, handleSelect, selectedCastTypeId, selectedCastGroupId, isGroupActive }) {
  const key = `${type.name}-${type.id}`
  const className = `p-4 cursor-pointer hover:bg-blue-500 ${selectedCastTypeId === type.id ? 'bg-blue-200' : ''}`

  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)
  const queryClient = useQueryClient()

  /* ****************** API Hooks ****************** */
  const { data: castGroupsData } = useQuery('castGroups', () => CastGroupService.fetchCastGroups(user.id, user.accessToken), {
    enabled: !!user.accessToken,
  })

  const { data: castsData } = useQuery('casts', () => CastService.fetchCasts(user.hashEmail, user.accessToken), { enabled: !!user.accessToken })

  const activateCastType = useMutation(() => CastTypeService.activateCastType(user.accessToken, type.id, selectedCastGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries('castTypes')
      queryClient.invalidateQueries('castGroups')
    },
  })

  const deactivateCastType = useMutation(() => CastTypeService.deactivateCastType(user.accessToken, type.id, selectedCastGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries('castTypes')
      queryClient.invalidateQueries('castGroups')
      queryClient.invalidateQueries('casts')
    },
  })

  /* ****************** JSX / Variables ****************** */
  const castGroup = castGroupsData?.find((group) => group.cast_type_ids?.find((typeId) => typeId === type.id) && selectedCastGroupId === group.id)
  const isActive = !!castGroup
  const activeCastName = castsData?.find((cast) => cast.cast_type_id === type.id && castGroup?.cast_ids.includes(cast.id))?.name

  /* ****************** Functions ****************** */
  const handleActive = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    isActive ? deactivateCastType.mutate() : activateCastType.mutate()
    ev.currentTarget.blur()
  }

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (activateCastType.isError) {
      setNotificationInfo({ type: 'error', message: activateCastType.error.message, show: true })
      activateCastType.reset()
    }

    if (activateCastType.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Activated Cast Type', show: true })
      activateCastType.reset()
    }

    if (deactivateCastType.isError) {
      setNotificationInfo({ type: 'error', message: deactivateCastType.error.message, show: true })
      deactivateCastType.reset()
    }

    if (deactivateCastType.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Deactivated Cast Type', show: true })
      deactivateCastType.reset()
    }
  }, [activateCastType, deactivateCastType, setNotificationInfo])

  return (
    <li key={key} className={className} data-cast-type-id={type.id} onClick={handleSelect}>
      <div className="flex" data-cast-type-id={type.id}>
        <div className="flex-none self-center" data-cast-type-id={type.id}>
          <ActiveIcon type="single" data-cast-type-id={type.id} handleClick={handleActive} isActive={isActive} />
        </div>
        <div className="flex-auto self-center pl-4" data-cast-type-id={type.id}>
          <p className="font-medium" data-cast-type-id={type.id}>
            {type.name}
          </p>
          <p className="flex items-center text-sm mt-1 text-gray-700" data-cast-type-id={type.id}>
            <SunIcon
              className={`flex-shrink-0 mr-1.5 h-5 w-5 ${activeCastName && isGroupActive && 'text-green-500'} ${
                activeCastName && !isGroupActive && 'text-yellow-500'
              }`}
            />
            <span className="truncate">{activeCastName ? activeCastName : 'Unassigned'}</span>
          </p>
        </div>
      </div>
    </li>
  )
}
