import { useMutation, useQueryClient } from 'react-query'
import DeleteIcon from './DeleteIcon'
import { useContext, useEffect, useState, useRef } from 'react'
import { UserContext } from '../../context/UserContext'
import { CastService } from '../../services/CastService'
import { NotificationContext } from '../../context/NotificationContext'
import EditIcon from './EditIcon'
import SaveIcon from './SaveIcon'
import LoadingAnimation from './LoadingAnimation'
import DragIcon from './DragIcon'
import { Draggable } from 'react-beautiful-dnd'

export default function CastValueItem({ castValue, handleSelect, index, castValues, selectedCastId, moveCard }) {
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)

  const queryClient = useQueryClient()

  const [isEdit, setIsEdit] = useState(false)
  const [newName, setNewName] = useState(undefined)
  const [loadingMessage, setLoadingMessage] = useState(undefined)

  /* ****************** Mutation Hook ****************** */
  const updateCastValue = useMutation(
    ({ user, values, selectedCastId }) => CastService.updateUserCastValues(user.hashEmail, user.accessToken, selectedCastId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('casts')
      },
    }
  )

  /* ****************** Functions ****************** */
  const handleInputEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      const cloneValues = castValues.map((value) => value)
      cloneValues[index] = newName
      setLoadingMessage('Updating...')
      updateCastValue.mutate({ user, selectedCastId, values: cloneValues })
    }
  }

  const handleDelete = () => {
    const cloneValues = castValues.map((value) => value)
    cloneValues.splice(index, 1)
    setLoadingMessage('Deleting...')
    updateCastValue.mutate({ user, selectedCastId, values: cloneValues })
  }

  const handleIsEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  const handleEditName = () => {
    const cloneValues = castValues.map((value) => value)
    cloneValues[index] = newName
    setLoadingMessage('Updating...')
    updateCastValue.mutate({ user, selectedCastId, values: cloneValues })
  }

  const handleInputChange = (ev) => {
    setNewName(ev.currentTarget.value)
  }

  /* ****************** JSX / Variables ****************** */
  const castValueNameJSX = isEdit ? (
    <input className="px-2" placeholder={castValue} onChange={handleInputChange} onKeyPress={handleInputEnterPress} />
  ) : updateCastValue.isLoading ? (
    <LoadingAnimation message={loadingMessage} />
  ) : (
    castValue
  )

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (updateCastValue.isSuccess && loadingMessage === 'Deleting...') {
      setNotificationInfo({ type: 'success', message: 'Deleted Cast Value', show: true })
      updateCastValue.reset()
    }

    if (updateCastValue.isError) {
      setNotificationInfo({ type: 'error', message: updateCastValue.error.message, show: true })
    }

    if (updateCastValue.isSuccess && loadingMessage === 'Updating...') {
      setNotificationInfo({ type: 'success', message: 'Updated Cast Value Name', show: true })
      updateCastValue.reset()
    }

    if (updateCastValue.isError) {
      setNotificationInfo({ type: 'error', message: updateCastValue.error.message, show: true })
      updateCastValue.reset()
    }
  }, [updateCastValue, updateCastValue.isError, updateCastValue.isSuccess, setNotificationInfo, loadingMessage])

  /* ****************** Render ****************** */
  return (
    <Draggable key={`${castValue}-${index}`} draggableId={`${castValue}-${index}`} index={index}>
      {(provided, snapshot) => (
        <li
          className={`p-4 ${snapshot.isDragging ? 'bg-blue-500 text-white' : 'opacity-100'}`}
          data-cast-value={castValue}
          onClick={handleSelect}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex">
            <div className="flex-none" {...provided.dragHandleProps}>
              <DragIcon />
            </div>
            <div className="flex-auto self-center pl-4" data-cast-id={castValue}>
              {castValueNameJSX}
            </div>
            <div className={`flex-none self-center ${snapshot.isDragging && 'invisible'}`}>
              {isEdit && <SaveIcon handleClick={handleEditName} />}
              <EditIcon handleClick={handleIsEdit} isEdit={isEdit} />
              <DeleteIcon handleClick={handleDelete} />
            </div>
          </div>
        </li>
      )}
    </Draggable>
  )
}
