async function fetchCompanies(accessToken) {
  const result = await fetch(`/api/v1/companies`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function createCompany(accessToken, payload) {
  const { name, domain, maxAllocatedCastTypes, redirectOrigin, castTypes, endpoints } = payload
  let createdCompany

  const companyPayload = {
    name,
    domain,
    maxAllocatedCastTypes,
    redirectOrigin,
  }

  const castTypesPayload = castTypes
  const endpointsPayload = endpoints

  // Create Company
  const companyResult = await fetch(`/api/v1/companies`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(companyPayload),
  })

  if (companyResult.ok) {
    createdCompany = await companyResult.json()
  } else {
    const errorMessage = await companyResult.text()

    throw Error(`${companyResult.status} ${errorMessage}`)
  }

  // Create Cast Types
  const allCastTypesResults = await Promise.all(
    castTypesPayload.map(async (castType) => {
      const castTypeResult = await fetch(`/api/v1/castTypes`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ name: castType.name, key: castType.key, companyId: createdCompany.id }),
      })

      if (castTypeResult.ok) {
        const data = await castTypeResult.json()

        const mergedDataFormId = { ...data, ...{ formCastTypeId: castType.id } }

        return mergedDataFormId
      } else {
        const errorMessage = await castTypeResult.text()
        throw Error(`${castTypeResult.status} ${errorMessage}`)
      }
    })
  )

  // Create Endpoints
  await Promise.all(
    endpointsPayload.map(async (endpoint) => {
      const castTypeId = allCastTypesResults.find((type) => type.formCastTypeId === endpoint.castTypeId)?.id

      if (!castTypeId) throw Error(`Unable to set cast type to endpoint`)

      const endpointResult = await fetch(`/api/v1/endpoints`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ url: endpoint.url, host: endpoint.host, path: endpoint.path, companyId: createdCompany.id, castTypeId }),
      })

      if (endpointResult.ok) {
        const data = await endpointResult.json()
        return data
      } else {
        const errorMessage = await endpointResult.text()
        throw Error(`${endpointResult.status} ${errorMessage}`)
      }
    })
  )
}

async function updateCompany(accessToken, payload) {
  const { companyId, name, domain, maxAllocatedCastTypes, castTypes, endpoints, redirectOrigin } = payload

  const companyPayload = {
    name,
    domain,
    maxAllocatedCastTypes,
    redirectOrigin,
  }

  const newCastTypes = castTypes.filter((type) => type.isNew)
  const existingCastTypes = castTypes.filter((type) => !type.isNew)
  const newEndpoints = endpoints.filter((endpoint) => endpoint.isNew)
  const existingEndpoints = endpoints.filter((endpoint) => !endpoint.isNew)

  // Update Company Details
  const result = await fetch(`/api/v1/companies/${companyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(companyPayload),
  })

  if (!result.ok) {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }

  // Create New Cast Types
  const newCastTypesResults = await Promise.all(
    newCastTypes.map(async (castType) => {
      const castTypeResult = await fetch(`/api/v1/castTypes`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ name: castType.name, key: castType.key, companyId }),
      })

      if (castTypeResult.ok) {
        const data = await castTypeResult.json()

        const mergedDataFormId = { ...data, ...{ formCastTypeId: castType.id } }

        return mergedDataFormId
      } else {
        const errorMessage = await castTypeResult.text()
        throw Error(`${castTypeResult.status} ${errorMessage}`)
      }
    })
  )

  // Create New Endpoints
  await Promise.all(
    newEndpoints.map(async (endpoint) => {
      const castTypeId =
        newCastTypesResults.find((type) => type.formCastTypeId === endpoint.castTypeId)?.id ||
        existingCastTypes.find((type) => type.id === endpoint.castTypeId)?.existingId

      if (!castTypeId) throw Error(`Unable to set cast type to endpoint`)

      const endpointResult = await fetch(`/api/v1/endpoints`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ url: endpoint.url, host: endpoint.host, path: endpoint.path, companyId, castTypeId }),
      })

      if (endpointResult.ok) {
        const data = await endpointResult.json()
        return data
      } else {
        const errorMessage = await endpointResult.text()
        throw Error(`${endpointResult.status} ${errorMessage}`)
      }
    })
  )

  // Update Company Cast Types
  const existingCastTypesResults = await Promise.all(
    existingCastTypes.map(async (castType) => {
      const castTypeResult = await fetch(`/api/v1/castTypes/${castType.existingId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({ name: castType.name, key: castType.key, companyId }),
      })

      if (castTypeResult.ok) {
        const data = await castTypeResult.json()

        const mergedDataFormId = { ...data, ...{ formCastTypeId: castType.id } }

        return mergedDataFormId
      } else {
        const errorMessage = await castTypeResult.text()
        throw Error(`${castTypeResult.status} ${errorMessage}`)
      }
    })
  )

  console.log(existingCastTypesResults)

  // Update Endpoints
  await Promise.all(
    existingEndpoints.map(async (endpoint) => {
      const castTypeId =
        newCastTypesResults.find((type) => type.formCastTypeId === endpoint.castTypeId)?.id ||
        existingCastTypesResults.find((type) => type.formCastTypeId === endpoint.castTypeId)?.id

      if (!castTypeId) throw Error(`Unable to set cast type to endpoint`)

      const endpointResult = await fetch(`/api/v1/endpoints/${endpoint.existingId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({ url: endpoint.url, host: endpoint.host, path: endpoint.path, companyId, castTypeId }),
      })

      if (!endpointResult.ok) {
        const errorMessage = await endpointResult.text()
        throw Error(`${endpointResult.status} ${errorMessage}`)
      }
    })
  )
}

export const CompanyService = {
  fetchCompanies,
  createCompany,
  updateCompany,
}
