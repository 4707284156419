import { useEffect, useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function CastTypeFormSelection({ form, index, values }) {
  const [query, setQuery] = useState('')
  const [selectedCastType, setSelectedCastType] = useState()
  const { pathname } = useLocation()

  /* ****************** Functions ****************** */
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  /* ****************** Variables / JSX ****************** */
  const filteredCastTypes =
    query === ''
      ? form.values.castTypes
      : form.values.castTypes?.filter((castType) => {
          return castType.name.toLowerCase().includes(query.toLowerCase())
        })

  const handleSelect = (castType) => {
    setSelectedCastType(castType)
    form.setFieldValue(`endpoints.${index}.castTypeId`, castType.id)
  }

  /* ****************** UseEffect ****************** */
  useEffect(() => {
    if (values.castTypeId && !!pathname.match(/edit/g)) {
      const castType = form.values.castTypes.find((type) => type.id === values.castTypeId) ?? ''
      setSelectedCastType(castType)
    }
  }, [form.values.castTypes, pathname, selectedCastType, selectedCastType?.id, values.castTypeId])

  /* ****************** Render ****************** */
  return (
    <Combobox as="div" value={selectedCastType} onChange={handleSelect}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Assign Cast Type</Combobox.Label>
      <div className="relative mt-1 mb-5">
        <Combobox.Input
          className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm `}
          onChange={(ev) => setQuery(ev.target.value)}
          type="text"
          displayValue={(castType) => castType.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredCastTypes.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCastTypes.map((castType, index) => (
              <Combobox.Option
                key={castType.name ? castType.name : index}
                value={castType}
                disabled={!castType.name}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>
                      {castType.name ? castType.name : 'Missing Cast Type Name'}
                    </span>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
