import { CheckIcon } from '@heroicons/react/outline'

export default function SaveIcon({ handleClick, castId }) {
  return (
    <button
      type="button"
      className="inline-flex items-center p-1 mx-2 border border-transparent rounded-full shadow-sm text-white hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 bg-green-400"
      data-cast-id={castId}
      onClick={handleClick}
    >
      <CheckIcon className="h-3 w-3" aria-hidden="true" />
    </button>
  )
}
