import AllowPageEndpointForm from '../atoms/AllowPageEndpointForm'

export default function AllowPageForm({ form, endpoint, endpointName, domain }) {
  const endpointDataJSX = endpoint.map((data, index) => (
    <AllowPageEndpointForm form={form} data={data} index={index} endpointName={endpointName} domain={domain} />
  ))

  return (
    <div className="col-span-6">
      {endpointDataJSX}
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Update <span className="ml-2 mr-2 font-bold">"{endpointName}"</span> Path
        </button>
      </div>
    </div>
  )
}
