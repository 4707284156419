import { useMutation, useQueryClient } from 'react-query'
import DeleteIcon from './DeleteIcon'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { CastGroupService } from '../../services/CastGroupService'
import { NotificationContext } from '../../context/NotificationContext'
import ActiveIcon from './ActiveIcon'
import EditIcon from './EditIcon'
import SaveIcon from './SaveIcon'
import LoadingAnimation from './LoadingAnimation'
import GroupInfoIcon from './GroupInfoIcon'

export default function CastGroupItem({ group, handleSelect, selectedCastGroupId, isLast }) {
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)

  const queryClient = useQueryClient()

  const [isEdit, setIsEdit] = useState(false)
  const [newName, setNewName] = useState(undefined)

  /* ****************** Mutation Hook ****************** */
  const deleteCastGroup = useMutation(({ user, castGroupId }) => CastGroupService.deleteCastGroup(user.accessToken, castGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries('castGroups')
    },
  })

  const updateCastGroup = useMutation(({ user, castGroupId, payload }) => CastGroupService.updateCastGroup(user.accessToken, castGroupId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('castGroups')
      setNewName(undefined)
      setIsEdit(false)
    },
  })

  const activateCastGroup = useMutation(({ user, castGroupId }) => CastGroupService.activateCastGroup(user.accessToken, castGroupId, user.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('castGroups')
    },
  })

  const deactivateCastGroup = useMutation(({ user, castGroupId }) => CastGroupService.deactivateCastGroup(user.accessToken, castGroupId, user.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('castGroups')
    },
  })

  /* ****************** JSX / Variables ****************** */
  const liClassName = `p-4 cursor-pointer hover:bg-blue-500 ${selectedCastGroupId === group.id ? 'bg-blue-200' : ''}`

  /* ****************** Functions ****************** */
  const handleDelete = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (!isEdit) deleteCastGroup.mutate({ user, castGroupId: group.id })
    else setIsEdit(false)
  }

  const handleEditName = () => {
    const payload = {
      name: newName,
      userId: group.user_id,
      castIds: group.cast_ids,
      castTypeIds: group.cast_type_ids,
      isActive: group.is_active,
    }

    updateCastGroup.mutate({ user, castGroupId: group.id, payload })
  }

  const handleInputEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      const payload = {
        name: newName,
        userId: group.user_id,
        castIds: group.cast_ids,
        castTypeIds: group.cast_type_ids,
        isActive: group.is_active,
      }

      updateCastGroup.mutate({ user, castGroupId: group.id, payload })
    }
  }

  const handleActive = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (group.is_active) deactivateCastGroup.mutate({ user, castGroupId: group.id })
    else activateCastGroup.mutate({ user, castGroupId: group.id })
  }

  const handleSelectCastGroup = (ev) => {
    handleSelect(Number(ev.target.getAttribute('data-group-id')))
  }

  const handleIsEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  // const handleInputBlur = () => {
  //   setIsEdit(false)
  // }

  const handleInputChange = (ev) => {
    setNewName(ev.currentTarget.value)
  }

  /* ****************** JSX ****************** */
  const castGroupNameJSX = isEdit ? (
    <input className="px-2" placeholder={group.name} onChange={handleInputChange} onKeyPress={handleInputEnterPress} />
  ) : updateCastGroup.isLoading ? (
    <LoadingAnimation />
  ) : (
    group.name
  )

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (deleteCastGroup.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Deleted Cast Group', show: true })
      deleteCastGroup.reset()
    }

    if (deleteCastGroup.isError) {
      setNotificationInfo({ type: 'error', message: deleteCastGroup.error.message, show: true })
    }

    if (updateCastGroup.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Updated Cast Group Name', show: true })
      updateCastGroup.reset()
    }

    if (updateCastGroup.isError) {
      setNotificationInfo({ type: 'error', message: updateCastGroup.error.message, show: true })
      updateCastGroup.reset()
    }

    if (activateCastGroup.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Activated Cast Group', show: true })
      activateCastGroup.reset()
    }

    if (activateCastGroup.isError) {
      setNotificationInfo({ type: 'error', message: activateCastGroup.error.message, show: true })
      activateCastGroup.reset()
    }
  }, [activateCastGroup, deleteCastGroup, deleteCastGroup.isError, deleteCastGroup.isSuccess, setNotificationInfo, updateCastGroup])

  /* ****************** Render ****************** */
  return (
    <li key={`${group.name}-${group.id}`} className={liClassName} data-group-id={group.id} onClick={handleSelectCastGroup}>
      <div className="flex" data-group-id={group.id}>
        <div className="flex-none" data-group-id={group.id}>
          <ActiveIcon
            iconType="triple"
            data-group-id={group.id}
            handleClick={handleActive}
            isActive={group.is_active}
            isDisabled={activateCastGroup.isLoading || deactivateCastGroup.isLoading}
          />
        </div>
        <div className="flex-auto self-center pl-4 font-medium" data-group-id={group.id}>
          {castGroupNameJSX}
          {/* <GroupInfoIcon group={group} /> */}
        </div>
        <div className="flex-none self-center" data-group-id={group.id}>
          {isEdit && <SaveIcon handleClick={handleEditName} />}
          <EditIcon handleClick={handleIsEdit} isEdit={isEdit} />
          {!isLast && !group.is_active ? <DeleteIcon handleClick={handleDelete} /> : null}
        </div>
      </div>
    </li>
  )
}
