import RoleSelection from '../atoms/RoleSelection'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { UserService } from '../../services/UserService'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { NotificationContext } from '../../context/NotificationContext'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import CompanyFormSelection from '../atoms/CompanyFormSelection'
import DomainMismatchError from '../atoms/DomainMismatchError'
import { CompanyService } from '../../services/CompanyService'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required(),
  email: Yup.string().email('Invalid Email Address').required(),
  companyId: Yup.number().min(1).required('A company is required'),
  roleId: Yup.number().min(1).required('A role is required'),
})

export default function UserProfile() {
  const [isNewForm, setIsNewForm] = useState(false)

  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)
  const [selectedUser] = useOutletContext()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  /* ****************** API Hooks ****************** */
  const createUser = useMutation(({ user, payload }) => UserService.createUser(user.accessToken, payload), {
    onSuccess: () => {
      setNotificationInfo({ type: 'success', message: 'Created User', show: true })
      navigate(-1)
    },
    onError: (error) => {
      setNotificationInfo({ type: 'error', message: error.message, show: true })
    },
  })

  const updateUser = useMutation(({ user, payload }) => UserService.updateUser(user.accessToken, payload, selectedUser.id), {
    onSuccess: () => {
      setNotificationInfo({ type: 'success', message: 'Updated User', show: true })
      queryClient.invalidateQueries('users')
    },
    onError: (error) => {
      setNotificationInfo({ type: 'error', message: error.message, show: true })
    },
  })

  const { data: companies } = useQuery('companies', () => CompanyService.fetchCompanies(user.accessToken), {
    enabled: !!user.accessToken,
  })

  /* ****************** Form Hook ****************** */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedUser ? selectedUser.name.split(' ')[0] : '',
      lastName: selectedUser ? selectedUser.name.split(' ')[1] : '',
      email: selectedUser ? selectedUser.email : '',
      emailHash: selectedUser ? selectedUser.hash_email : '',
      companyId: selectedUser ? selectedUser.company_id : user.roleId === 3 ? user.companyId : 0,
      roleId: selectedUser ? selectedUser.role_id : 0,
    },
    validationSchema,
    onSubmit: (values) => {
      if (!!pathname.match(/create/g)) createUser.mutate({ user, payload: values })
      if (!!pathname.match(/edit/g)) updateUser.mutate({ user, payload: values })
    },
  })

  /* ****************** JSX / Variables ****************** */
  const firstNameFormError =
    formik.errors.firstName && formik.touched.firstName ? (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {formik.errors.firstName}
      </p>
    ) : null

  const lastNameFormError =
    formik.errors.lastName && formik.touched.lastName ? (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {formik.errors.lastName}
      </p>
    ) : null

  const emailFormError =
    formik.errors.email && formik.touched.email ? (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {formik.errors.email}
      </p>
    ) : null

  /* ****************** UseEffect ****************** */
  useEffect(() => {
    if (!selectedUser && !isNewForm) {
      formik.resetForm()
      setIsNewForm(true)
    }
  }, [formik, isNewForm, selectedUser])

  /* ****************** Render ****************** */
  if (!selectedUser && pathname === '/admin/users') {
    return <></>
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
              <p className="mt-1 text-sm text-gray-500">Create a new user for DemoBlox</p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    autoComplete="given-name"
                    placeholder="John"
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      formik.errors.firstName && formik.touched.firstName && 'border-red-600'
                    }`}
                  />
                  {firstNameFormError}
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    autoComplete="family-name"
                    placeholder="Smith"
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      formik.errors.lastName && formik.touched.lastName && 'border-red-600'
                    }`}
                  />
                  {lastNameFormError}
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    autoComplete="email"
                    placeholder="j.smith@email.com"
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      formik.errors.email && formik.touched.email && 'border-red-600'
                    }`}
                  />
                  {emailFormError}
                  <DomainMismatchError
                    mismatch={
                      companies && !formik.values.email.includes(companies?.find((company) => company.id === formik.values.companyId)?.domain ?? '')
                    }
                    isTouched={formik.touched.email}
                  />
                </div>

                {/* <div className="col-span-6 sm:col-span-6">
                  <div className="flex justify-between">
                    <label htmlFor="emailHash" className="block text-sm font-medium text-gray-700">
                      Email Hash
                    </label>
                  </div>
                  <input
                    type="text"
                    name="emailHash"
                    id="emailHash"
                    onChange={formik.handleChange}
                    value={formik.values.emailHash}
                    autoComplete="emailHash"
                    disabled
                    placeholder="Autofilled on creation based on email"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">User Permissions</h3>
              <p className="mt-1 text-sm text-gray-500">Set user's permissions for Demoblox</p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <CompanyFormSelection
                    companyId={formik.values.companyId}
                    setFieldValue={formik.setFieldValue}
                    isError={!!formik.errors.companyId}
                    isTouched={formik.touched.companyId}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <RoleSelection
                    roleId={formik.values.roleId}
                    setFieldValue={formik.setFieldValue}
                    isError={!!formik.errors.roleId}
                    isTouched={formik.touched.roleId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {!!pathname.match(/create/g) ? 'Create User' : 'Update User'}
          </button>
        </div>
      </div>
    </form>
  )
}
