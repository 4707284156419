import React, { useEffect, useContext, useState, useRef } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { useCookies } from 'react-cookie'
import { UserContext } from '../context/UserContext'
import logo from '../common/assets/blox.png'
import { useNavigate } from 'react-router-dom'
import sendMessageToExtension from '../common/helpers/sendMessagetoExtension'

export default function Logout() {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['demoblox'])
  const { setUser, user } = useContext(UserContext)
  const navigate = useNavigate()

  const isLoggedOut = useRef(false)
  const signOutUserId = useRef(user.id)
  console.log(signOutUserId)

  const onLogoutSuccess = async (res) => {
    console.log('success', res)
    removeCookie(['demoblox'])
    setUser({
      accessToken: null,
      name: null,
      hashEmail: null,
      companyId: null,
      roleId: null,
    })

    if (!isLoggedOut.current) isLoggedOut.current = true
  }

  const onFailure = (res) => {
    console.log('failure', res)
  }

  const { signOut } = useGoogleLogout({ onLogoutSuccess, onFailure })

  useEffect(() => {
    if (!isLoggedOut.current) {
      signOut()
    }
  }, [isLoggedOut, signOut])

  useEffect(() => {
    if (isLoggedOut.current && !user.accessToken && !Object.prototype.hasOwnProperty.call(cookies, 'demoblox')) {
      sendMessageToExtension({
        action: 'logout',
        details: {
          user: {
            id: signOutUserId.current,
          },
        },
      })

      navigate('/login')
    }
  }, [cookies, isLoggedOut, navigate, user.accessToken])

  return (
    <div className="grid place-items-center h-screen">
      <div className="text-center">
        <img src={logo} alt="" />
        <h1>Logging Out...</h1>
      </div>
    </div>
  )
}
