import { ExclamationIcon } from '@heroicons/react/solid'
import React from 'react'

export default function LoginAlert({ companyName }) {
  return (
    <div className="flex justify-center">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">You must login with your {companyName} account in order to continue using Demoblox</p>
          </div>
        </div>
      </div>
    </div>
  )
}
