import { useFormik, FormikProvider, FieldArray } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { NotificationContext } from '../../context/NotificationContext'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import CastTypeForm from '../molecules/CastTypeForm'
import EndPointForm from '../molecules/EndPointForm'
import { CompanyService } from '../../services/CompanyService'
import { CastTypeService } from '../../services/CastTypeService'
import { EndpointService } from '../../services/EndpointService'
import usePrevious from '../../hooks/usePrevious'

// const validationSchema = Yup.object().shape({
//   name: Yup.string().required('Company name required'),
//   url: Yup.string().required('Company url required'),
//   maxAllocatedCastTypes: Yup.number().min(1).required(),
//   castTypes: Yup.array(Yup.object.shape({ })),
//   roleId: Yup.number().min(1).required('A role is required'),
// })

export default function CompanyProfile() {
  const [isNewForm, setIsNewForm] = useState(false)

  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)
  const [selectedCompany] = useOutletContext()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const hasFetchedEndpointsData = useRef(false)
  const hasFetchedCastTypesData = useRef(false)
  const prevSelectedCompanyId = usePrevious(selectedCompany?.id)

  /* ****************** API Hooks ****************** */
  const createCompany = useMutation(({ user, payload }) => CompanyService.createCompany(user.accessToken, payload), {
    onSuccess: () => {
      setNotificationInfo({ type: 'success', message: 'Created Company', show: true })
    },
    onError: (error) => {
      setNotificationInfo({ type: 'error', message: error.message, show: true })
    },
  })

  const updateCompany = useMutation(({ user, payload }) => CompanyService.updateCompany(user.accessToken, payload, selectedCompany.id), {
    onSuccess: () => {
      setNotificationInfo({ type: 'success', message: 'Updated User', show: true })
      queryClient.invalidateQueries('users')
    },
    onError: (error) => {
      setNotificationInfo({ type: 'error', message: error.message, show: true })
    },
  })

  const {
    data: endpointsData,
    isLoading: isEndpointsLoading,
    isError: isEndpointsError,
  } = useQuery(['companyEndpoints', selectedCompany?.id], () => EndpointService.fetchEndpointsByCompany(user.accessToken, selectedCompany.id), {
    enabled: !!selectedCompany?.id,
  })
  const {
    data: castTypesData,
    isLoading: isCastTypesLoading,
    isError: isCastTypesError,
  } = useQuery(['companyCastTypes', selectedCompany?.id], () => CastTypeService.fetchCastTypesByCompany(user.accessToken, selectedCompany.id), {
    enabled: !!selectedCompany?.id,
  })

  /* ****************** Form Hook ****************** */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedCompany ? selectedCompany.name : '',
      domain: selectedCompany ? selectedCompany.domain : '',
      maxAllocatedCastTypes: selectedCompany ? selectedCompany.max_allocated_cast_types : 3,
      redirectOrigin: selectedCompany ? selectedCompany.redirect_origin : '',
      castTypes: [{ name: '', key: '', id: 0, isNew: true }],
      endpoints: [{ url: '', host: '', path: '', reportName: '', castTypeId: 0, isNew: true }],
    },
    // validationSchema,
    onSubmit: (values) => {
      if (!!pathname.match(/create/g)) createCompany.mutate({ user, payload: values })
      if (!!pathname.match(/edit/g)) updateCompany.mutate({ user, payload: { ...values, ...{ companyId: selectedCompany.id } } })
    },
  })

  /* ****************** JSX / Variables ****************** */
  const nameFormError =
    formik.errors.name && formik.touched.name ? (
      <p className="mt-2 text-sm text-red-600" id="name-error">
        {formik.errors.name}
      </p>
    ) : null

  const urlFormError =
    formik.errors.domain && formik.touched.domain ? (
      <p className="mt-2 text-sm text-red-600" id="url-error">
        {formik.errors.domain}
      </p>
    ) : null

  const maxAllocationFormError =
    formik.errors.maxAllocatedCastTypes && formik.touched.maxAllocatedCastTypes ? (
      <p className="mt-2 text-sm text-red-600" id="maxAllocatedCastTypes-error">
        {formik.errors.maxAllocatedCastTypes}
      </p>
    ) : null

  const redirectOriginFormError =
    formik.errors.redirectOrigin && formik.touched.redirectOrigin ? (
      <p className="mt-2 text-sm text-red-600" id="url-error">
        {formik.errors.redirectOrigin}
      </p>
    ) : null

  /* ****************** UseEffect ****************** */
  useEffect(() => {
    if (castTypesData && !isCastTypesLoading && !isCastTypesError && !hasFetchedCastTypesData.current) {
      formik.setFieldValue(
        'castTypes',
        castTypesData.map((type, index) => ({ name: type.name, key: type.key ?? '', id: index + 1, isNew: false, existingId: type.id }))
      )
      hasFetchedCastTypesData.current = true
    }
  }, [castTypesData, formik, isCastTypesError, isCastTypesLoading])

  useEffect(() => {
    if (endpointsData && !isEndpointsLoading && !isEndpointsError && !hasFetchedEndpointsData.current && castTypesData && hasFetchedCastTypesData) {
      formik.setFieldValue(
        'endpoints',
        endpointsData.map((endpoint) => ({
          url: endpoint.url,
          host: endpoint.host,
          path: endpoint.path,
          reportName: endpoint.report_name,
          castTypeId: castTypesData.findIndex((type) => type.id === endpoint.cast_type_id) + 1,
          isNew: false,
          existingId: endpoint.id,
        }))
      )
      hasFetchedEndpointsData.current = true
    }
  }, [castTypesData, endpointsData, formik, isEndpointsError, isEndpointsLoading])

  // Reset form on going to add
  useEffect(() => {
    if (!selectedCompany && !isNewForm) {
      formik.resetForm()
      setIsNewForm(true)
      hasFetchedCastTypesData.current = false
      hasFetchedEndpointsData.current = false
    }
  }, [formik, isNewForm, selectedCompany])

  // Reset form on changing company selection
  useEffect(() => {
    if (selectedCompany?.id !== prevSelectedCompanyId) {
      formik.resetForm()
      setIsNewForm(true)
      hasFetchedCastTypesData.current = false
      hasFetchedEndpointsData.current = false
    }
  }, [formik, isNewForm, selectedCompany?.id, prevSelectedCompanyId])

  /* ****************** Render ****************** */
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-6">
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Company Information</h3>
                <p className="mt-1 text-sm text-gray-500">Create a new company for DemoBlox</p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                        formik.errors.name && formik.touched.name && 'border-red-600'
                      }`}
                    />
                    {nameFormError}
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
                      Domain Url
                    </label>
                    <input
                      type="text"
                      name="domain"
                      onChange={formik.handleChange}
                      value={formik.values.domain}
                      placeholder="company.com"
                      className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                        formik.errors.domain && formik.touched.domain && 'border-red-600'
                      }`}
                    />
                    {urlFormError}
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="maxAllocatedCastTypes" className="block text-sm font-medium text-gray-700">
                      Max Allocated Cast Types
                    </label>
                    <input
                      type="number"
                      name="maxAllocatedCastTypes"
                      onChange={formik.handleChange}
                      value={formik.values.maxAllocatedCastTypes}
                      placeholder="3"
                      className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                        formik.errors.maxAllocatedCastTypes && formik.touched.maxAllocatedCastTypes && 'border-red-600'
                      }`}
                    />
                    {maxAllocationFormError}
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="maxAllocatedCastTypes" className="block text-sm font-medium text-gray-700">
                      Redirect Origin
                    </label>
                    <input
                      type="text"
                      name="redirectOrigin"
                      onChange={formik.handleChange}
                      value={formik.values.redirectOrigin}
                      placeholder="https://company.com"
                      className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                        formik.errors.redirectOrigin && formik.touched.redirectOrigin && 'border-red-600'
                      }`}
                    />
                    {redirectOriginFormError}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Company Cast Types</h3>
                <p className="mt-1 text-sm text-gray-500">Set company's cast types</p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-4">
                  <FieldArray name="castTypes" component={CastTypeForm} />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Company Endpoints</h3>
                <p className="mt-1 text-sm text-gray-500">Set company's endpoints</p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-4">
                  <FieldArray name="endpoints" component={EndPointForm} />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {!!pathname.match(/create/g) ? 'Create Company' : 'Update Company'}
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}
