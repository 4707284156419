async function fetchAllowPages(accessToken) {
  const result = await fetch(`/api/v1/scenes/allowPages`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchAllowPage(accessToken, allowPageId) {
  const result = await fetch(`/api/v1/scenes/allowPages/${allowPageId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function updateAllowPage(accessToken, allowPageId, payload) {
  const result = await fetch(`/api/v1/scenes/allowPages/${allowPageId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(payload),
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

export const ScenesService = {
  fetchAllowPages,
  fetchAllowPage,
  updateAllowPage,
}
