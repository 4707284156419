import { useContext, useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { useQuery } from 'react-query'
import { UserContext } from '../../context/UserContext'
import LoadingAnimation from './LoadingAnimation'
import { CastGroupService } from '../../services/CastGroupService'

export default function CloneCastSelection({ setSelectedGroup, selectedGroup }) {
  const [query, setQuery] = useState('')

  const { user } = useContext(UserContext)
  /* ****************** API HOOKS ****************** */

  const { data, isLoading } = useQuery('castGroupsByCompany', () => CastGroupService.fetchCastGroupsByCompany(user.companyId, user.accessToken), {
    enabled: !!user.accessToken || !!user.companyId,
  })

  /* ****************** Functions ****************** */
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  /* ****************** Variables / JSX ****************** */
  const filteredGroups =
    query === ''
      ? data
      : data?.filter((group) => {
          return `${group.name.toLowerCase()} ${group.user_name.toLowerCase()}`.includes(query.toLowerCase())
        })

  const handleSelect = (group) => {
    setSelectedGroup(group)
  }

  /* ****************** Render ****************** */
  if (!data || isLoading) {
    return <LoadingAnimation message={''} isCenter={true} />
  }

  return (
    <Combobox as="div" value={selectedGroup} onChange={handleSelect}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Search {user.companyName} Cast Groups to Clone</Combobox.Label>
      <div className="relative mt-1 mb-5">
        <Combobox.Input
          className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm `}
          onChange={(ev) => setQuery(ev.target.value)}
          type="text"
          displayValue={(group) => `${group.name} - ${data.find((castGroup) => castGroup.id === group.id).user_name}`}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredGroups.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredGroups.map((group) => (
              <Combobox.Option
                key={group.id}
                value={group}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>
                      {group.name} - <span className="text-xs">{group.user_name}</span>
                    </span>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
