import { useContext, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { useMutation, useQueryClient } from 'react-query'
import { CastGroupService } from '../../services/CastGroupService'
import { useEffect } from 'react'
import { NotificationContext } from '../../context/NotificationContext'
import LoadingAnimation from './LoadingAnimation'

export default function NewCastGroupItem() {
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)

  const queryClient = useQueryClient()

  const [newCastGroupName, setNewCastGroupName] = useState('')

  /* ****************** Mutation Hook ****************** */
  const createCastGroup = useMutation(({ user, payload }) => CastGroupService.createCastGroup(user.accessToken, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('castGroups')
    },
  })

  /* ****************** JSX / Variables ****************** */
  const liClassName = 'p-4'
  const inputClassName = `w-full px-5 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md ${
    createCastGroup.isLoading ? 'ring-green-500' : ''
  } ${createCastGroup.isError ? 'ring-red-500' : ''}`

  /* ****************** Functions ****************** */
  const handleChange = (ev) => {
    setNewCastGroupName(ev.currentTarget.value)
  }

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      const payload = {
        name: newCastGroupName,
        userId: user.id,
      }

      createCastGroup.mutate({ user, payload })
    }
  }

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (createCastGroup.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Created Cast Group', show: true })
      createCastGroup.reset()
      setNewCastGroupName('')
    }

    if (createCastGroup.isError) {
      setNotificationInfo({ type: 'error', message: createCastGroup.error.message, show: true })
      createCastGroup.reset()
    }
  }, [createCastGroup, createCastGroup.isError, createCastGroup.isSuccess, setNotificationInfo])

  /* ****************** Render ****************** */
  return (
    <li key="new-cast-entry" className={liClassName}>
      {createCastGroup.isLoading ? <LoadingAnimation /> : null}
      {createCastGroup.isLoading ? null : (
        <input
          className={inputClassName}
          type="text"
          onChange={handleChange}
          value={newCastGroupName}
          placeholder={newCastGroupName === '' ? 'Add New Cast Group' : undefined}
          onKeyPress={handleKeyPress}
        />
      )}
    </li>
  )
}
