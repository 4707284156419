import { useContext, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { useMutation, useQueryClient } from 'react-query'
import { CastService } from '../../services/CastService'
import { useEffect } from 'react'
import { NotificationContext } from '../../context/NotificationContext'
import LoadingAnimation from './LoadingAnimation'

export default function NewCastValue({ selectedCastId, castValues }) {
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)
  const queryClient = useQueryClient()

  const [newCastValueName, setNewCastValueName] = useState('')

  /* ****************** Mutation Hook ****************** */
  const createCastValue = useMutation(
    ({ user, values, selectedCastId }) => CastService.updateUserCastValues(user.hashEmail, user.accessToken, selectedCastId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('casts')
      },
    }
  )

  /* ****************** JSX / Variables ****************** */
  const liClassName = 'p-4'
  const inputClassName = `w-full px-5 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md ${
    createCastValue.isLoading ? 'ring-green-500' : ''
  } ${createCastValue.isError ? 'ring-red-500' : ''}`

  /* ****************** Functions ****************** */
  const handleChange = (ev) => {
    setNewCastValueName(ev.currentTarget.value)
  }

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      const updatedValues = castValues.map((value) => value)
      updatedValues.push(newCastValueName)
      createCastValue.mutate({ user, values: updatedValues, selectedCastId })
    }
  }

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (createCastValue.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Created Cast Value', show: true })
      createCastValue.reset()
      setNewCastValueName('')
    }

    if (createCastValue.isError) {
      setNotificationInfo({ type: 'error', message: createCastValue.error.message, show: true })
      createCastValue.reset()
    }
  }, [createCastValue, createCastValue.isError, createCastValue.isSuccess, setNotificationInfo])

  /* ****************** Render ****************** */
  return (
    <li key="new-cast-entry" className={liClassName}>
      {createCastValue.isLoading ? <LoadingAnimation /> : null}
      {createCastValue.isLoading ? null : (
        <input
          className={inputClassName}
          type="text"
          onChange={handleChange}
          value={newCastValueName}
          placeholder={newCastValueName === '' ? 'Add New Cast Value' : undefined}
          onKeyPress={handleKeyPress}
        />
      )}
    </li>
  )
}
