import React, { useContext, useEffect } from 'react'
import Dashboard from '../pages/Dashboard'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import { UserContext } from '../context/UserContext'
import Nav from '../components/organisms/Nav'
// import Settings from '../pages/Settings'
import Admin from '../pages/Admin'
import Users from '../components/organisms/Users'
import Companies from '../components/organisms/Companies'
import UserProfile from '../components/organisms/UserProfile'
import CompanyProfile from '../components/molecules/CompanyProfile'
import CloneCastGroup from '../pages/CloneCastGroup'
import Scenes from '../pages/Scenes'
import AllowPages from '../components/organisms/AllowPages'
import BlockActions from '../components/organisms/BlockActions'
import AutoFills from '../components/organisms/AutoFills'
import AllowPagesProfile from '../components/molecules/AllowPagesProfile'

function App() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useContext(UserContext)
  /* ****************** Functions ****************** */

  /* ****************** Use Effects ****************** */
  // Redirect login if no access token is present
  useEffect(() => {
    if (user.accessToken === null && pathname !== '/login') {
      navigate('/login', { replace: true })
    }
  }, [navigate, pathname, user.accessToken])

  /* ****************** Render ****************** */
  return (
    <Nav hidden={pathname.match(/login/g) || pathname.match(/logout/g)}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="clone" element={<CloneCastGroup />} />
        {/* <Route path="settings" element={<Settings />} /> */}
        <Route path="admin" element={<Admin />}>
          <Route path="scenes" element={<Scenes />}>
            <Route path="allow-pages" element={<AllowPages />}>
              <Route path="edit" element={<AllowPagesProfile />} />
            </Route>
            <Route path="block-actions" element={<BlockActions />}>
              <Route path="edit" element={<div>hi</div>} />
            </Route>
            <Route path="auto-fills" element={<AutoFills />}>
              <Route path="edit" element={<div>hi</div>} />
            </Route>
          </Route>
          <Route path="companies" element={<Companies />}>
            <Route path="create" element={<CompanyProfile />} />
            <Route path="edit" element={<CompanyProfile />} />
          </Route>
          <Route path="users" element={<Users />}>
            <Route path="create" element={<UserProfile />} />
            <Route path="edit" element={<UserProfile />} />
          </Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
      </Routes>
    </Nav>
  )
}

export default App
