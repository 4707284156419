import { Field } from 'formik'
import AllowPageQueryMatches from '../molecules/AllowPageQueryMatches'
import AllowPageMatchSelector from './AllowPageMatchSelector'

export default function AllowPageEndpointForm({ form, data, index, domain, endpointName }) {
  return (
    <div>
      <label className="block mt-4 text-sm font-medium text-gray-700">
        <span className="text-lg text-indigo-500">{index + 1}.</span>
      </label>

      <label className="block text-sm font-medium text-gray-700">Demo Shot Title</label>
      <Field
        type="text"
        name={`allow_urls.${domain}.${endpointName}.${index}.demoShot.messageTitle`}
        onChange={form.handleChange}
        className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
      />

      <label className="block mt-4 text-sm font-medium text-gray-700">Demo Shot Message</label>
      <Field
        type="text"
        name={`allow_urls.${domain}.${endpointName}.${index}.demoShot.message`}
        onChange={form.handleChange}
        className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
      />

      <AllowPageMatchSelector form={form} index={index} endpointName={endpointName} domain={domain} data={data.query.match} />
      <AllowPageQueryMatches form={form} index={index} endpointName={endpointName} domain={domain} data={data.query} />
    </div>
  )
}
