import EndpointFormInput from '../atoms/EndpointFormInput'

export default function EndPointForm({ push, form }) {
  // console.log(form.values)

  const addButtonJSX = (
    <button
      type="button"
      onClick={() => push({ url: '', host: '', path: '', reportName: '', castTypeId: 0, isNew: true })}
      className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 `}
    >
      Add Endpoint
    </button>
  )

  return (
    <>
      {form.values.endpoints.map((values, index) => (
        <EndpointFormInput
          key={`${index}-endpoint`}
          form={form}
          values={values}
          index={index}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
        />
      ))}
      <div className="col-span-6 sm:col-span-6 text-right">{addButtonJSX}</div>
    </>
  )
}
