async function createEndpoint(accessToken, payload) {
  const result = await fetch(`/api/v1/endpoints`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchEndpointsByCompany(accessToken, companyId) {
  const result = await fetch(`/api/v1/endpoints/companies/${companyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

export const EndpointService = {
  createEndpoint,
  fetchEndpointsByCompany,
}
