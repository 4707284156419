/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */

import classNames from '../../common/helpers/classNames'

export default function AllowPageEndpointList({ endpoints, setSelectedEndpoint, selectedEndpoint, domain }) {
  const endpointListJSX = endpoints.map((endpoint) => (
    <li key={`${domain}${endpoint}`} className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">{endpoint}</p>
        </div>
        <div>
          <div
            onClick={() => setSelectedEndpoint(`${domain}${endpoint}`)}
            className={classNames(
              selectedEndpoint === `${domain}${endpoint}` ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'bg-white text-gray-700',
              'inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full cursor-pointer'
            )}
          >
            View
          </div>
        </div>
      </div>
    </li>
  ))

  return (
    <div>
      <div className="flow-root mt-6">
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {endpointListJSX}
        </ul>
      </div>
    </div>
  )
}
