import * as queryString from 'query-string'
import QueryMatchSwitch from '../atoms/QueryMatchSwitch'
import { ExclamationIcon } from '@heroicons/react/solid'
import classNames from '../../common/helpers/classNames'

export default function AllowPageQueryMatches({ form, domain, data, endpointName, index }) {
  const querySource = queryString.parse(data.string)
  const queriesSourceKeys = Object.keys(querySource)

  const queries = form.values.allow_urls[domain][endpointName][index].query.object
  const queriesKey = Object.keys(queries)

  const matchType = form.values.allow_urls[domain][endpointName][index].query.match

  if (!queriesSourceKeys.length) {
    return (
      <div className="p-4 mt-4 rounded-md bg-yellow-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">No Query Matches</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>Changing query match will not alter anthing on this endpoint.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (matchType === 'ignore') {
    return <></>
  }

  return (
    <div className={classNames(matchType === 'exact' ? 'cursor-not-allowed opacity-50' : '')}>
      {queriesSourceKeys.map((key) => (
        <QueryMatchSwitch
          query={key}
          value={queriesKey.includes(key) && matchType !== 'partial' ? queries[key] : querySource[key]}
          isActive={data.object.hasOwnProperty(key)}
          domain={domain}
          endpointName={endpointName}
          index={index}
          form={form}
        />
      ))}
    </div>
  )
}
