import { useContext } from "react"
import { UserContext } from "../../context/UserContext";
import {
  UserIcon
} from '@heroicons/react/outline';

export default function ProfileImage() {
  const { user } = useContext(UserContext);

  const userProfileJSX = (
    <img
      className="h-8 w-8 rounded-full"
      src={user.profileImgUrl}
      alt=""
    />
  );

  const noProfileImageJSX = (
      <div

        className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <UserIcon className="h-6 w-6" aria-hidden="true" />
    </div>

  )

  return user.profileImgUrl ? userProfileJSX : noProfileImageJSX;
}