import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export default function Admin() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === '/admin') navigate('users')
  }, [navigate, pathname])

  return (
    <div>
      <Outlet />
    </div>
  )
}
