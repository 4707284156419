import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { UserProvider } from './context/UserContext'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { NotificationProvider } from './context/NotificationContext'
import { getCookieJS, setCookieJS } from './common/helpers/global'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error) => {
      if (error.message.includes('401')) {
        const response = await fetch('/api/v1/auth/google/reauthenticate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refreshToken: getCookieJS('demoblox-rt') }),
        })
        // eslint-disable-next-line no-debugger
        debugger
        if (!response.ok) throw new Error('Failed to get new access token')
        const data = await response.json()
        console.log(data)
        setCookieJS('demoblox', data.id_token, data.expiry_date)
        await queryClient.refetchQueries()
      }
    },
  }),
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <NotificationProvider>
              <App />
            </NotificationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </UserProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
