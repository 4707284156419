import { Outlet } from 'react-router-dom'
import ScenesNav from '../components/atoms/ScenesNav'

export default function Scenes() {
  return (
    <>
      <ScenesNav />
      <Outlet />
    </>
  )
}
