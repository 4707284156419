import React, { useEffect, useState, useContext } from 'react'
import CastValueItem from '../atoms/CastValue'
import NewCastValue from '../atoms/NewCastValue'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useMutation, useQueryClient } from 'react-query'
import { CastService } from '../../services/CastService'
import { UserContext } from '../../context/UserContext'
import { NotificationContext } from '../../context/NotificationContext'

export default function CastValueList({ castValues, selectedCastId, isCastsLoading }) {
  const [values, _setValues] = useState([])

  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)
  const queryClient = useQueryClient()

  /* ****************** Mutation Hook ****************** */
  const updateCastValues = useMutation(
    ({ user, values, selectedCastId }) => CastService.updateUserCastValues(user.hashEmail, user.accessToken, selectedCastId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('casts')
      },
    }
  )

  /* ****************** Functions ****************** */
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }
    const newOrder = reorder(values, result.source.index, result.destination.index)
    _setValues(newOrder)
    updateCastValues.mutate({ user, selectedCastId, values: newOrder })
  }

  /* ****************** JSX ****************** */
  const castValuesJSX = values.map((value, index) => (
    <CastValueItem
      key={`${value}-${index}`}
      castValue={value}
      handleSelect={() => {}}
      selectedCastValues={() => {}}
      index={index}
      castValues={castValues}
      selectedCastId={selectedCastId}
    />
  ))

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (updateCastValues.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Moved Cast Value Order', show: true })
      updateCastValues.reset()
    }

    if (updateCastValues.isError) {
      setNotificationInfo({ type: 'error', message: updateCastValues.error.message, show: true })
    }
  }, [setNotificationInfo, updateCastValues])

  useEffect(() => {
    if (castValues) {
      _setValues(castValues)
    }
  }, [castValues])

  /* ****************** Render ****************** */
  return (
    <div className="bg-white shadow overflow-hidden rounded-md">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul className="divide-y divide-gray-200" {...provided.droppableProps} ref={provided.innerRef}>
              {isCastsLoading ? 'loading...' : castValuesJSX}
              {provided.placeholder}
              <NewCastValue selectedCastId={selectedCastId} castValues={values} />
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
