import { useContext, useState, useEffect } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { useQuery } from 'react-query'
import { UserContext } from '../../context/UserContext'
import { CompanyService } from '../../services/CompanyService'
import LoadingAnimation from './LoadingAnimation'

export default function CompanyFormSelection({ setFieldValue, companyId, isError, isTouched }) {
  const { user } = useContext(UserContext)

  const [query, setQuery] = useState('')
  const [selectedCompany, setSelectedCompany] = useState()
  const isOwnerRole = user.roleId === 3

  /* ****************** API HOOKS ****************** */
  const { isLoading, data } = useQuery('companies', () => CompanyService.fetchCompanies(user.accessToken), {
    enabled: !!user.accessToken,
  })

  /* ****************** Functions ****************** */
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  /* ****************** Variables / JSX ****************** */
  const filteredCompany =
    query === ''
      ? data
      : data?.filter((company) => {
          return company.name.toLowerCase().includes(query.toLowerCase())
        })

  const handleSelect = (company) => {
    setSelectedCompany(company)
    setFieldValue('companyId', company.id)
  }

  /* ****************** UseEffect ****************** */
  useEffect(() => {
    if (companyId && !selectedCompany && data) setSelectedCompany(data.find((company) => company.id === companyId))
  }, [data, companyId, selectedCompany])

  /* ****************** Render ****************** */
  if (!data || isLoading) {
    return <LoadingAnimation message={''} isCenter={true} />
  }

  if (isOwnerRole) {
    return (
      <>
        <div className="flex justify-between">
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">
            Company
          </label>
        </div>
        <input
          type="text"
          name="companyId"
          id="companyId"
          value={data.find((company) => company.id === companyId).name}
          autoComplete="companyId"
          disabled
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
        />
      </>
    )
  }

  return (
    <Combobox as="div" value={selectedCompany} onChange={handleSelect}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Company</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm ${
            isError && isTouched && 'border-red-600'
          }`}
          onChange={(ev) => setQuery(ev.target.value)}
          type="text"
          displayValue={(company) => company.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredCompany.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCompany.map((company) => (
              <Combobox.Option
                key={company.id}
                value={company}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{company.name}</span>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
