import { Switch } from '@headlessui/react'
import classNames from '../../common/helpers/classNames'

export default function QueryMatchSwitch({ query, value, isActive, form, domain, endpointName, index }) {
  isActive && console.log(value)
  const queries = form.values.allow_urls[domain][endpointName][index].query.object
  const querySourceKeyPair = form.values.allow_urls[domain][endpointName][index].query.string
    .replace('?', '')
    .split('&')
    .find((queryKey) => queryKey.includes(query))
    .split('=')
  const matchType = form.values.allow_urls[domain][endpointName][index].query.match

  const handleSwitchChange = (checked) => {
    if (checked) {
      queries[querySourceKeyPair[0]] = querySourceKeyPair[1]
    } else {
      delete queries[query]
    }

    form.setFieldValue(`allow_urls[${domain}][${endpointName}][${index}].query.object`, queries)
  }

  const handleInputChange = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    queries[querySourceKeyPair[0]] = ev.target.value
    form.setFieldValue(`allow_urls[${domain}][${endpointName}][${index}].query.object`, queries)
  }

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={isActive}
        disabled={matchType === 'exact'}
        onChange={handleSwitchChange}
        className={classNames(
          isActive ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-2',
          matchType === 'exact' ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isActive ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="mt-2 ml-3">
        <span className="text-sm font-medium text-gray-900">{query} </span>
      </Switch.Label>
      <div className="mt-2 ml-2">
        <input
          className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={value}
          type="text"
          disabled={matchType === 'exact'}
          onChange={handleInputChange}
        />
      </div>
    </Switch.Group>
  )
}
