import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { createContext } from 'react'
import { useCookies } from 'react-cookie'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const queryClient = useQueryClient()

  const [user, setUser] = useState({
    accessToken: null,
    name: null,
    hashEmail: null,
    companyId: null,
    roleId: null,
    profileImgUrl: null,
    id: null,
    companyName: null,
  })

  const navigate = useNavigate()

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['demoblox', 'demoblox-rt'])

  const handleUnauthorized = () => {
    removeCookie(['demoblox'])
    setUser({
      accessToken: null,
      name: null,
      hashEmail: null,
      companyId: null,
      roleId: null,
      companyName: null,
    })

    navigate('/login')
  }

  const handleRefresh = async () => {
    const response = await fetch('/api/v1/auth/google/reauthenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken: cookies['demoblox-rt'] }),
    })
    // eslint-disable-next-line no-debugger
    debugger
    if (response.ok) {
      handleUnauthorized()
      return
    }

    const data = await response.json()
    setCookie('demoblox', data.id_token, { expires: new Date(1653316608038) })
    setUser({ ...user, ...{ accessToken: data.id_token } })
    queryClient.clear()
  }

  return <UserContext.Provider value={{ user, setUser, handleUnauthorized, handleRefresh }}>{children}</UserContext.Provider>
}
