import PageTitle from '../components/atoms/PageTitle'
import { useQuery } from 'react-query'
import { useContext, useState, useEffect, useCallback } from 'react'
import { UserContext } from '../context/UserContext'
import CastTypeItem from '../components/atoms/CastTypeItem'
import CastItem from '../components/atoms/CastItem'
import ReactDOM from 'react-dom'
import NewCastItem from '../components/atoms/NewCastItem'
import { CastService } from '../services/CastService'
import { CastTypeService } from '../services/CastTypeService'
import { CastGroupService } from '../services/CastGroupService'
import usePrevious from '../hooks/usePrevious'
import CastGroupItem from '../components/atoms/CastGroupItem'
import NewCastGroupItem from '../components/atoms/NewCastGroupItem'
import SelectPreviousSection from '../components/atoms/SelectPreviousSection'
import CastValueList from '../components/molecules/CastValueList'

export default function Dashboard() {
  const { user, handleUnauthorized, handleRefresh } = useContext(UserContext)
  const [selectedCastGroupId, setSelectedCastGroupId] = useState(null)
  const [selectedCastTypeId, setSelectedCastTypeId] = useState(null)
  const [selectedCastId, setSelectedCastId] = useState(null)
  const [casts, setCasts] = useState(null)
  const [castValues, setCastValues] = useState(null)

  const prevSelectedCastTypeId = usePrevious(selectedCastTypeId)
  const prevSelectedCastGroupId = usePrevious(selectedCastGroupId)

  /* ****************** API Hooks ****************** */
  const {
    isLoading: isCastGroupsLoading,
    error: castGroupsError,
    isSuccess: isCastGroupsSuccess,
    isError: isCastGroupsError,
    data: castGroupsData,
  } = useQuery('castGroups', () => CastGroupService.fetchCastGroups(user.id, user.accessToken), { enabled: !!user.accessToken })

  const {
    isLoading: isCastTypesLoading,
    error: castTypesError,
    isSuccess: isCastTypesSuccess,
    isError: isCastTypesError,
    data: castTypesData,
  } = useQuery('castTypes', () => CastTypeService.fetchCastTypes(user.hashEmail, user.accessToken), { enabled: !!user.accessToken })

  const {
    isLoading: isCastsLoading,
    error: castsError,
    isSuccess: isCastsSuccess,
    isError: isCastsError,
    data: castsData,
  } = useQuery('casts', () => CastService.fetchCasts(user.hashEmail, user.accessToken), { enabled: !!user.accessToken })

  /* ****************** Functions ****************** */
  const handleCastGroupSelect = useCallback((groupId) => {
    setSelectedCastGroupId(groupId)
  }, [])

  const handleCastTypeSelect = useCallback((ev) => {
    setSelectedCastTypeId(Number(ev.target.getAttribute('data-cast-type-id')))
  }, [])

  /* ****************** Variables ****************** */
  // const activeGroup = castGroupsData?.find((group) => group.is_active) ?? []

  /* ****************** JSX ****************** */
  const castGroupsJSX = castGroupsData?.map((group) => (
    <CastGroupItem
      key={group.name}
      group={group}
      handleSelect={handleCastGroupSelect}
      selectedCastGroupId={selectedCastGroupId}
      isLast={castGroupsData.length === 1}
    />
  ))

  const castTypesJSX = castTypesData?.map((type) => {
    const isGroupActive = !!castGroupsData?.find((group) => group.id === selectedCastGroupId)?.is_active

    return (
      <CastTypeItem
        key={type.name}
        type={type}
        handleSelect={handleCastTypeSelect}
        selectedCastTypeId={selectedCastTypeId}
        selectedCastGroupId={selectedCastGroupId}
        isGroupActive={isGroupActive}
      />
    )
  })

  const castsJSX = casts?.map((cast) => (
    <CastItem
      key={cast.name}
      cast={cast}
      handleSelect={setSelectedCastId}
      selectedCastId={selectedCastId}
      selectedCastGroupId={selectedCastGroupId}
      selectedCastTypeId={selectedCastTypeId}
    />
  ))

  const castTypesListJSX = (
    <div className="bg-white shadow overflow-hidden rounded-md">
      <ul className="divide-y divide-gray-200">{isCastTypesLoading ? 'loading...' : castTypesJSX}</ul>
    </div>
  )

  const castListJSX = (
    <div className="bg-white shadow overflow-hidden rounded-md">
      <ul className="divide-y divide-gray-200">
        {isCastsLoading ? 'loading...' : castsJSX}
        <NewCastItem selectedCastTypeId={selectedCastTypeId} />
      </ul>
    </div>
  )

  /* ****************** UseEffects ****************** */
  useEffect(() => {
    // handle changing cast and cast values
    if (selectedCastTypeId && prevSelectedCastTypeId !== selectedCastTypeId) {
      const existingCasts = castsData.filter((cast) => cast.cast_type_id === selectedCastTypeId)
      ReactDOM.unstable_batchedUpdates(() => {
        setCastValues(null)
        setCasts(null)
        setSelectedCastId(null)
        setCasts(existingCasts)
      })
    }

    // handling changing cast values
    if (selectedCastTypeId && prevSelectedCastTypeId === selectedCastTypeId) {
      const existingCasts = castsData.filter((cast) => cast.cast_type_id === selectedCastTypeId)
      setCasts(existingCasts)
    }
  }, [castsData, prevSelectedCastTypeId, selectedCastTypeId])

  // Reset selected id's when a group id changes
  useEffect(() => {
    if (selectedCastGroupId && prevSelectedCastGroupId !== selectedCastGroupId) {
      ReactDOM.unstable_batchedUpdates(() => {
        setSelectedCastId(null)
        setSelectedCastTypeId(null)
      })
    }
  }, [prevSelectedCastGroupId, selectedCastGroupId])

  // Updated the cast values when a selected cast id exists
  useEffect(() => {
    if (selectedCastId) {
      const existingCastValues = casts.find((cast) => cast.id === selectedCastId)
      setCastValues(existingCastValues.cast_values)
    }
  }, [casts, selectedCastId])

  // handle redirect to login if unauthorized
  useEffect(() => {
    if (isCastGroupsError || isCastTypesError || isCastsError) {
      if (
        castGroupsError.message.includes('Token is expired') ||
        castTypesError.message.includes('Token is expired') ||
        castsError.message.includes('Token is expired')
      )
        return handleRefresh()

      if (castGroupsError.message.includes('401') || castTypesError.message.includes('401') || castsError.message.includes('401'))
        return handleUnauthorized()
    }
  }, [castGroupsError, castTypesError, castsError, handleRefresh, handleUnauthorized, isCastGroupsError, isCastTypesError, isCastsError])
  /* ****************** Render ****************** */
  return (
    <>
      <PageTitle title="Cast Management" />
      {/* <ActiveGroupDetails activeGroup={activeGroup} /> */}
      <div className="grid grid-cols-4 gap-4">
        <div>
          <h2 className="mb-2">
            <span>1.</span> Groups
          </h2>
          <div className="bg-white shadow overflow-hidden rounded-md">
            <ul className="divide-y divide-gray-200">
              {isCastGroupsLoading ? 'loading...' : castGroupsJSX}
              <NewCastGroupItem />
            </ul>
          </div>
        </div>
        <div>
          <h2 className="mb-2">
            <span>2.</span> Cast Types
          </h2>
          {selectedCastGroupId ? castTypesListJSX : <SelectPreviousSection title={'Select a group to load cast types'} message={''} />}
        </div>
        <div>
          <h2 className="mb-2">
            <span>3.</span> Casts
          </h2>
          {selectedCastTypeId ? castListJSX : <SelectPreviousSection title={'Select a cast type to load casts'} message={''} />}
        </div>
        <div>
          <h2 className="mb-2">
            <span>4.</span> Cast Values
          </h2>
          {selectedCastId ? (
            <CastValueList castValues={castValues} selectedCastId={selectedCastId} isCastsLoading={isCastsLoading} />
          ) : (
            <SelectPreviousSection title={'Select a cast to load cast values'} message={''} />
          )}
        </div>
      </div>
    </>
  )
}
