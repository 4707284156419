import { PlusIcon } from '@heroicons/react/solid'

export default function SelectPreviousSection({ title, message }) {
  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-12">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className=" mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-400">{title}</h3>
      <p className="mt-1 text-sm text-gray-400">{message}</p>
    </div>
  )
}
