import sendMessageToExtension from '../common/helpers/sendMessagetoExtension'

async function fetchCastGroups(userId, accessToken) {
  const result = await fetch(`/api/v1/castGroups?userId=${userId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchCastGroupsByCompany(companyId, accessToken) {
  const result = await fetch(`/api/v1/castGroups/companies/${companyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function createCastGroup(accessToken, payload) {
  const result = await fetch(`/api/v1/castGroups`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })

  if (result.ok) {
    const jsonResponse = await result.json()

    return jsonResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function updateCastGroup(accessToken, castGroupId, payload) {
  const result = await fetch(`/api/v1/castGroups/${castGroupId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(payload),
  })

  if (result.ok) {
    const jsonResponse = await result.json()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return jsonResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function activateCastGroup(accessToken, castGroupId, userId) {
  const result = await fetch(`/api/v1/castGroups/${castGroupId}/activate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({ userId }),
  })

  if (result.ok) {
    const textResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return textResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function deactivateCastGroup(accessToken, castGroupId, userId) {
  const result = await fetch(`/api/v1/castGroups/${castGroupId}/deactivate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ userId }),
  })

  if (result.ok) {
    const textResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return textResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function cloneCastGroup(accessToken, payload) {
  const result = await fetch(`/api/v1/castGroups/clone`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })

  if (result.ok) {
    const textResponse = await result.text()

    return textResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function deleteCastGroup(accessToken, castGroupId) {
  const result = await fetch(`/api/v1/castGroups/${castGroupId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'DELETE',
  })

  if (result.ok) {
    const textResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return textResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

export const CastGroupService = {
  fetchCastGroups,
  createCastGroup,
  updateCastGroup,
  deleteCastGroup,
  activateCastGroup,
  fetchCastGroupsByCompany,
  cloneCastGroup,
  deactivateCastGroup,
}
