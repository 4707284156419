import { GoogleLogin } from 'react-google-login'
import React, { useCallback, useEffect, useContext } from 'react'
import logo from '../common/assets/DemoBloxLogo.png'
import { UserContext } from '../context/UserContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import sendMessageToExtension from '../common/helpers/sendMessagetoExtension'
import sendMessageToExternalExtension from '../common/helpers/sendMessagetoExternalExtension'
import LoginAlert from '../components/atoms/LoginAlert'
import { addHoursToDate } from '../common/helpers/global'

export default function Login() {
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookies] = useCookies(['demoblox'])
  let [searchParams] = useSearchParams()

  const requireLoginParam = searchParams.get('require_login')
  const companyNameParam = searchParams.get('company_name')
  console.log('require', requireLoginParam)

  /* ****************** Functions ****************** */
  const handleLogin = useCallback(
    async (googleData) => {
      console.log('login creds', googleData)

      const res = await fetch('/api/v1/auth/google', {
        method: 'POST',
        body: JSON.stringify({
          token: googleData.code,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (res.ok) {
        const { company_id, hash_email, name, token, role_id, id, company_name, redirect_origin, refreshToken } = await res.json()

        const scenesResponse = await fetch('/api/v1/users/scenes', {
          method: 'GET',
          headers: {
            authorization: `Bearer ${token}`,
          },
        })

        const { allow_urls, block_urls, auto_fills } = await scenesResponse.json()

        // Set Cookies
        if (refreshToken) setCookie('demoblox-rt', refreshToken, { expires: addHoursToDate(1, true) })
        setCookie('demoblox', token, { expires: addHoursToDate(1, false) })

        setUser({
          companyId: company_id,
          hashEmail: hash_email,
          name,
          accessToken: token,
          roleId: role_id,
          profileImgUrl: googleData?.profileObj?.imageUrl ?? null,
          id: id,
          companyName: company_name,
        })

        sendMessageToExtension({
          action: 'login',
          details: {
            user: { id, allow_urls, block_urls, auto_fills },
            company: {
              id: company_id,
              name: company_name,
              redirect_origin: redirect_origin,
            },
            enable: !!requireLoginParam,
          },
        })

        sendMessageToExternalExtension({
          action: 'login',
          details: {
            user: {
              id,
              allow_urls,
              block_urls,
              auto_fills,
              role: {
                id: role_id,
                external: [4, 5].includes(role_id),
              },
            },
            company: {
              id: company_id,
              name: company_name,
              redirect_origin: redirect_origin,
            },
            enable: !!requireLoginParam,
          },
        })
      } else {
        console.log('failed to login')
        removeCookies(['demoblox'])
        // TODO: Message Alerts Completion
      }
    },
    [removeCookies, requireLoginParam, setCookie, setUser]
  )

  /* ****************** UseEffects ****************** */
  useEffect(() => {
    if (user.accessToken && cookies.demoblox) navigate('/')
    console.log()
  }, [user.accessToken, cookies.demoblox, navigate])

  /* ****************** Render ****************** */
  return (
    <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="w-auto h-12 mx-auto" src={logo} alt="Workflow" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          <h2 className="text-3xl font-extrabold text-center text-gray-900">Sign in to your account</h2>
          {requireLoginParam && <LoginAlert companyName={companyNameParam} />}
          <div className="mt-5 text-center">
            <GoogleLogin
              className="px-5 py-1 text-white bg-blue-800"
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Log in with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              // isSignedIn={true}
              cookiePolicy={'single_host_origin'}
              responseType="code"
              accessType="offline"
              // prompt="consent"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
