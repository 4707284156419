import { useMutation, useQueryClient, useQuery } from 'react-query'
import DeleteIcon from './DeleteIcon'
import { useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { CastService } from '../../services/CastService'
import { NotificationContext } from '../../context/NotificationContext'
import ActiveIcon from './ActiveIcon'
import EditIcon from './EditIcon'
import SaveIcon from './SaveIcon'
import LoadingAnimation from './LoadingAnimation'
import { CastGroupService } from '../../services/CastGroupService'

export default function CastItem({ cast, handleSelect, selectedCastId, selectedCastGroupId, selectedCastTypeId }) {
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)

  const queryClient = useQueryClient()

  const [isEdit, setIsEdit] = useState(false)
  const [newName, setNewName] = useState(undefined)

  /* ****************** API Hook ****************** */
  const { data: castGroupsData } = useQuery('castGroups', () => CastGroupService.fetchCastGroups(user.id, user.accessToken), {
    enabled: !!user.accessToken,
  })

  const deleteCast = useMutation(({ user, castId }) => CastService.deleteUserCast(user.accessToken, castId), {
    onSuccess: () => {
      queryClient.invalidateQueries('casts')
      queryClient.invalidateQueries('casts')
    },
  })

  const updateCastName = useMutation(({ user, castId, name }) => CastService.updateUserCastName(user.hashEmail, user.accessToken, castId, name), {
    onSuccess: () => {
      queryClient.invalidateQueries('casts')
      setNewName(undefined)
      setIsEdit(false)
    },
  })

  const activateCast = useMutation(() => CastService.activateCast(user.accessToken, cast.id, selectedCastGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries('casts')
      queryClient.invalidateQueries('castGroups')
    },
  })

  const deactivateCast = useMutation(() => CastService.deactivateCast(user.accessToken, cast.id, selectedCastGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries('casts')
      queryClient.invalidateQueries('castGroups')
    },
  })

  /* ****************** JSX / Variables ****************** */
  const liClassName = `p-4 cursor-pointer hover:bg-blue-500 ${selectedCastId === cast.id ? 'bg-blue-200' : ''}`

  const selectedCastGroup = useMemo(() => castGroupsData.find((group) => group.id === selectedCastGroupId), [castGroupsData, selectedCastGroupId])
  const isActive = !!selectedCastGroup?.cast_ids?.find((castId) => castId === cast.id)
  const isDisabled = !selectedCastGroup?.cast_type_ids?.find((typeId) => selectedCastTypeId === typeId)

  /* ****************** Functions ****************** */
  const handleDelete = () => {
    deleteCast.mutate({ user, castId: cast.id })
  }

  const handleEditName = () => {
    updateCastName.mutate({ user, castId: cast.id, name: newName })
  }

  const handleInputEnterPress = (ev) => {
    if (ev.key === 'Enter') updateCastName.mutate({ user, castId: cast.id, name: newName })
  }

  const handleActive = (ev) => {
    isActive ? deactivateCast.mutate() : activateCast.mutate()
    ev.currentTarget.blur()
  }

  const handleSelectCast = (ev) => {
    handleSelect(Number(ev.target.getAttribute('data-cast-id')))
  }

  const handleIsEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  // const handleInputBlur = () => {
  //   setIsEdit(false)
  // }

  const handleInputChange = (ev) => {
    setNewName(ev.currentTarget.value)
  }

  /* ****************** JSX ****************** */
  const castNameJSX = isEdit ? (
    <input className="px-2" placeholder={cast.name} onChange={handleInputChange} onKeyPress={handleInputEnterPress} />
  ) : updateCastName.isLoading ? (
    <LoadingAnimation />
  ) : (
    cast.name
  )

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (deleteCast.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Deleted Cast', show: true })
      deleteCast.reset()
    }

    if (deleteCast.isError) {
      setNotificationInfo({ type: 'error', message: deleteCast.error.message, show: true })
    }

    if (updateCastName.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Updated Cast Name', show: true })
      updateCastName.reset()
    }

    if (updateCastName.isError) {
      setNotificationInfo({ type: 'error', message: updateCastName.error.message, show: true })
      updateCastName.reset()
    }

    if (activateCast.isError) {
      setNotificationInfo({ type: 'error', message: activateCast.error.message, show: true })
      activateCast.reset()
    }

    if (activateCast.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Activated Cast', show: true })
      activateCast.reset()
    }

    if (deactivateCast.isError) {
      setNotificationInfo({ type: 'error', message: deactivateCast.error.message, show: true })
      deactivateCast.reset()
    }

    if (deactivateCast.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Activated Cast', show: true })
      deactivateCast.reset()
    }
  }, [activateCast, deactivateCast, deleteCast, setNotificationInfo, updateCastName])

  /* ****************** Render ****************** */
  return (
    <li key={`${cast.name}-${cast.id}`} className={liClassName} data-cast-id={cast.id} onClick={handleSelectCast}>
      <div className="flex " data-cast-id={cast.id}>
        <div className="flex-none" data-cast-id={cast.id}>
          <ActiveIcon type="single" handleClick={handleActive} isActive={isActive} isDisabled={isDisabled} />
        </div>
        <div className="flex-auto self-center pl-4" data-cast-id={cast.id}>
          {castNameJSX}
        </div>
        <div className="flex-none self-center" data-cast-id={cast.id}>
          {isEdit && <SaveIcon handleClick={handleEditName} />}
          <EditIcon handleClick={handleIsEdit} isEdit={isEdit} />
          <DeleteIcon handleClick={handleDelete} />
        </div>
      </div>
    </li>
  )
}
