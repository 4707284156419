import sendMessageToExtension from '../common/helpers/sendMessagetoExtension'

async function fetchCastTypes(hashEmail, accessToken) {
  const result = await fetch(`/api/v1/castTypes?userHashEmail=${hashEmail}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchCastType(accessToken, id) {
  const result = await fetch(`/api/v1/castTypes/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function fetchCastTypesByCompany(accessToken, companyId) {
  const result = await fetch(`/api/v1/castTypes/companies/${companyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })

  if (result.ok) {
    const data = await result.json()

    return data
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function activateCastType(accessToken, castTypeId, groupId) {
  const result = await fetch(`/api/v1/castTypes/${castTypeId}/activate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ groupId }),
  })

  if (result.ok) {
    const testResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return testResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

async function deactivateCastType(accessToken, castTypeId, groupId) {
  const result = await fetch(`/api/v1/castTypes/${castTypeId}/deactivate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ groupId }),
  })

  if (result.ok) {
    const testResponse = await result.text()

    sendMessageToExtension({
      action: 'clearDemoData',
    })

    return testResponse
  } else {
    const errorMessage = await result.text()

    throw Error(`${result.status} ${errorMessage}`)
  }
}

export const CastTypeService = {
  fetchCastTypes,
  activateCastType,
  fetchCastTypesByCompany,
  deactivateCastType,
  fetchCastType,
}
