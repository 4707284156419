import { useFormik, FormikProvider } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { useContext, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { NotificationContext } from '../../context/NotificationContext'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { ScenesService } from '../../services/ScenesService'
import AllowPageForm from './AllowPageForm'
import AllowPageEndpointList from '../atoms/AllowPageEndpointList'

export default function AllowPagesProfile() {
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)
  const [selectedAllowPage] = useOutletContext()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedEndpoint, setSelectedEndpoint] = useState('')

  /* ****************** API Hooks ****************** */
  const updateAllowPage = useMutation(({ user, payload, id }) => ScenesService.updateAllowPage(user.accessToken, id, payload), {
    onSuccess: () => {
      setNotificationInfo({ type: 'success', message: 'Updated Allow Pages', show: true })
      queryClient.invalidateQueries('scenes')
    },
    onError: (error) => {
      setNotificationInfo({ type: 'error', message: error.message, show: true })
    },
  })

  let encodedDomains = {}

  if (selectedAllowPage) {
    for (let domain in selectedAllowPage.allow_urls) {
      const encodedDomain = domain.replaceAll('.', 'special')
      encodedDomains[encodedDomain] = selectedAllowPage.allow_urls[domain]
    }
  }

  /* ****************** Form Hook ****************** */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedAllowPage ? selectedAllowPage.name : '',
      description: selectedAllowPage ? selectedAllowPage.description : '',
      allow_urls: encodedDomains,
    },
    onSubmit: (values) => {
      console.log('submitting')
      let decodedDomains = {}

      for (let encodedDomain in values.allow_urls) {
        decodedDomains[encodedDomain.replaceAll('special', '.')] = values.allow_urls[encodedDomain]
      }

      if (!!pathname.match(/edit/g))
        updateAllowPage.mutate({ user, payload: { ...values, ...{ allow_urls: decodedDomains } }, id: selectedAllowPage.id })
    },
  })

  /* ****************** JSX / Variables ****************** */
  const nameFormError =
    formik.errors.name && formik.touched.name ? (
      <p className="mt-2 text-sm text-red-600" id="name-error">
        {formik.errors.name}
      </p>
    ) : null

  const descriptionFormError =
    formik.errors.description && formik.touched.description ? (
      <p className="mt-2 text-sm text-red-600" id="url-error">
        {formik.errors.description}
      </p>
    ) : null

  const domainPagesUrls = () => {
    const domainPageJSX = []
    for (const domain in encodedDomains) {
      domainPageJSX.push(
        <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-4">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{domain.replaceAll('special', '.')}</h3>
              <p className="mt-1 text-sm text-gray-500">Allow Page Domain</p>
              <div>
                <AllowPageEndpointList
                  endpoints={Object.keys(encodedDomains[domain])}
                  domain={domain.replaceAll('special', '.')}
                  setSelectedEndpoint={setSelectedEndpoint}
                  selectedEndpoint={selectedEndpoint}
                />
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-4">
                {selectedEndpoint.includes(domain.replaceAll('special', '.')) && (
                  <AllowPageForm
                    form={formik}
                    endpoint={formik.values.allow_urls[domain][new URL(`https://${selectedEndpoint}`).pathname]}
                    endpointName={new URL(`https://${selectedEndpoint}`).pathname}
                    domain={domain}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return domainPageJSX
  }

  /* ****************** Render ****************** */
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-6">
          <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Scene Information</h3>
                <p className="mt-1 text-sm text-gray-500">Edit Scene Details</p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Scene Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                        formik.errors.name && formik.touched.name && 'border-red-600'
                      }`}
                    />
                    {nameFormError}
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <input
                      type="text"
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      placeholder="https://company.com"
                      className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                        formik.errors.description && formik.touched.description && 'border-red-600'
                      }`}
                    />
                    {descriptionFormError}
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Update Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          {domainPagesUrls()}
        </div>
      </form>
    </FormikProvider>
  )
}
