import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import CompanyManagementHeader from '../atoms/CompanyManagementHeader'
import CompanySelection from '../atoms/CompanySelection'

export default function Companies() {
  const { pathname } = useLocation()

  const [selectedCompany, setSelectedCompany] = useState()

  useEffect(() => {
    if (!!pathname.match(/create/g)) setSelectedCompany(undefined)
  }, [pathname])

  return (
    <div>
      <CompanyManagementHeader />
      {(pathname === '/admin/companies' || !!pathname.match('/admin/companies/edit')) && (
        <CompanySelection setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany} />
      )}
      <Outlet context={[selectedCompany]} />
    </div>
  )
}
