import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import AllowPagesSelection from '../atoms/AllowPagesSelection'

export default function AllowPages() {
  const [selectedValue, setSelectedValue] = useState()

  return (
    <div>
      <AllowPagesSelection setSelectedValue={setSelectedValue} selectedValue={selectedValue} />
      <Outlet context={[selectedValue]} />
    </div>
  )
}
