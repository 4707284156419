import { useContext, useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { useQuery } from 'react-query'
import { UserContext } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom'
import LoadingAnimation from './LoadingAnimation'
import { ScenesService } from '../../services/ScenesService'

export default function AllowPagesSelection({ setSelectedValue, selectedValue }) {
  const [query, setQuery] = useState('')

  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  /* ****************** API HOOKS ****************** */
  const { isLoading, data } = useQuery('scenes', () => ScenesService.fetchAllowPages(user.accessToken), {
    enabled: !!user.accessToken,
  })

  /* ****************** Functions ****************** */
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  /* ****************** Variables / JSX ****************** */
  const filteredUsers =
    query === ''
      ? data
      : data?.filter((allowPage) => {
          return allowPage.name.toLowerCase().includes(query.toLowerCase())
        })

  const handleSelect = (allowPage) => {
    setSelectedValue(allowPage)
    navigate(`edit`)
  }

  /* ****************** UseEffect ****************** */

  /* ****************** Render ****************** */
  if (!data || isLoading) {
    return <LoadingAnimation message={''} isCenter={true} />
  }

  return (
    <Combobox as="div" value={selectedValue} onChange={handleSelect}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Search Allow Pages</Combobox.Label>
      <div className="relative mt-1 mb-5">
        <Combobox.Input
          className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm `}
          onChange={(ev) => setQuery(ev.target.value)}
          type="text"
          displayValue={(allowPage) => `${allowPage.name}`}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
          <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredUsers.length > 0 && (
          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredUsers.map((allowPage) => (
              <Combobox.Option
                key={allowPage.id}
                value={allowPage}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>
                      {allowPage.name} - <span className="text-xs">{allowPage.description}</span>
                    </span>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
