import CastTypeFormInput from '../atoms/CastTypeFormInput'

export default function CastTypesForm({ push, form }) {
  const disableAdd = form.values.castTypes.length >= form.values.maxAllocatedCastTypes

  const addButtonJSX = (
    <button
      type="button"
      disabled={disableAdd}
      onClick={() => push({ name: '', key: '', isNew: true })}
      className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
        disableAdd && 'disabled:opacity-25 cursor-not-allowed'
      }`}
    >
      {disableAdd ? 'Max Types Reached' : 'Add Cast Type'}
    </button>
  )

  return (
    <>
      {form.values.castTypes.map((values, index) => (
        <CastTypeFormInput
          key={`${index}-castType`}
          values={values}
          index={index}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          setFieldValue={form.setFieldValue}
        />
      ))}
      <div className="col-span-6 sm:col-span-6 text-right">{addButtonJSX}</div>
    </>
  )
}
