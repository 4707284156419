import { useContext, useEffect } from 'react'
import { useMutation, useQueries } from 'react-query'
import { UserContext } from '../../context/UserContext'
import { CastService } from '../../services/CastService'
import { CastTypeService } from '../../services/CastTypeService'
import { DuplicateIcon } from '@heroicons/react/solid'
import { CastGroupService } from '../../services/CastGroupService'
import { NotificationContext } from '../../context/NotificationContext'

export default function CloneCastProfile({ selectedGroup, clonedInSessionGroupIds, setClonedInSessionGroupIds }) {
  /* ****************** Selectors/States ****************** */
  const { user } = useContext(UserContext)
  const { setNotificationInfo } = useContext(NotificationContext)

  /* ****************** Api Hooks ****************** */
  const castQueries = useQueries(
    !!selectedGroup
      ? selectedGroup.cast_ids.map((id) => ({
          queryKey: ['cast', id],
          queryFn: () => CastService.fetchCast(user.accessToken, id),
        }))
      : []
  )

  const castTypeQueries = useQueries(
    !!selectedGroup
      ? selectedGroup.cast_type_ids.map((id) => ({
          queryKey: ['castType', id],
          queryFn: () => CastTypeService.fetchCastType(user.accessToken, id),
        }))
      : []
  )

  const cloneCastGroup = useMutation(({ accessToken, payload }) => CastGroupService.cloneCastGroup(accessToken, payload))

  /* ****************** Functions ****************** */
  const handleClone = () => {
    setClonedInSessionGroupIds((prevState) => [...prevState, ...[selectedGroup.id]])
    cloneCastGroup.mutate({ accessToken: user.accessToken, payload: { groupId: selectedGroup.id, userId: user.id } })
  }

  /* ****************** JSX/Variables ****************** */
  const casts = castQueries?.map(
    (query, i) =>
      query.isSuccess && (
        <div key={`${i}-${query.data.name}`} className="inline-block mr-6 mb-6 align-top">
          <div className="font-medium text-indigo-600">
            {castTypeQueries?.find((typeQuery) => typeQuery.isSuccess && typeQuery.data.id === query.data.cast_type_id)?.data.name}
          </div>
          <ol>
            {query.data.cast_values?.map((value, index) => (
              <li key={`${index}-${value}`}>{value}</li>
            ))}
          </ol>
        </div>
      )
  )

  /* ****************** UseEffects ****************** */
  useEffect(() => {
    if (cloneCastGroup.isError) {
      setNotificationInfo({ type: 'error', message: cloneCastGroup.error.message, show: true })
      cloneCastGroup.reset()
    }

    if (cloneCastGroup.isSuccess) {
      setNotificationInfo({ type: 'success', message: 'Cloned Group', show: true })
      cloneCastGroup.reset()
    }
  }, [cloneCastGroup, setNotificationInfo])

  /* ****************** Render ****************** */
  if (!selectedGroup || !!castQueries.find((query) => query.isLoading) || !!castTypeQueries.find((query) => query.isLoading)) return <></>

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{selectedGroup.name}</h3>
          <p className="text-sm text-gray-500">{selectedGroup.user_name}</p>
        </div>
        <button
          type="button"
          className={`inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            clonedInSessionGroupIds.includes(selectedGroup.id) && 'disabled: opacity-25 cursor-not-allowed'
          }`}
          onClick={handleClone}
          disabled={clonedInSessionGroupIds.includes(selectedGroup.id) || cloneCastGroup.isLoading}
        >
          <DuplicateIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Clone Group
        </button>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6 ">
            <dt className="text-sm font-medium text-gray-500">Active Casts</dt>
            <dd className="mt-1 text-sm text-gray-900">{casts}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
