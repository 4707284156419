import { Field } from 'formik'
import CastTypeFormSelection from './CastTypeFormSelection'

export default function EndpointFormInput({ errors, touched, handleChange, values, index, form }) {
  // const errorState = errors.endpoints[index] && touched.endpoints[index] && 'border-red-600'

  return (
    <>
      <div className="col-span-6 sm:col-span-6">
        <label htmlFor={`endpoints.${index}.name`} className="block text-sm font-medium text-gray-700">
          <span className="text-lg text-indigo-500">{index + 1}.</span> Url
        </label>
        <Field
          type="text"
          name={`endpoints.${index}.url`}
          onChange={handleChange}
          value={values.url}
          placeholder=""
          className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${''}`}
        />
      </div>

      <div className="col-span-6 sm:col-span-6">
        <label htmlFor={`endpoints.${index}.host`} className="block text-sm font-medium text-gray-700">
          Host
        </label>
        <Field
          type="text"
          name={`endpoints.${index}.host`}
          onChange={handleChange}
          value={values.host}
          placeholder=""
          className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${''}`}
        />
      </div>

      <div className="col-span-6 sm:col-span-6">
        <label htmlFor={`endpoints.${index}.path`} className="block text-sm font-medium text-gray-700">
          Path
        </label>
        <Field
          type="text"
          name={`endpoints.${index}.path`}
          onChange={handleChange}
          value={values.path}
          placeholder=""
          className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${''}`}
        />
      </div>

      <div className="col-span-6 sm:col-span-6 border-b border-gray-200 pb-7">
        <CastTypeFormSelection form={form} index={index} values={values} />
      </div>
    </>
  )
}
