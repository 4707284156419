import React, { useState } from 'react'
import CloneCastSelection from '../components/atoms/CloneCastSelection'
import PageTitle from '../components/atoms/PageTitle'
import CloneCastProfile from '../components/organisms/CloneCastProfile'

export default function CloneCastGroup() {
  const [selectedGroup, setSelectedGroup] = useState()
  const [clonedInSessionGroupIds, setClonedInSessionGroupIds] = useState([])

  return (
    <>
      <PageTitle title="Clone ReCasts" />
      <CloneCastSelection setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} />
      <CloneCastProfile
        selectedGroup={selectedGroup}
        clonedInSessionGroupIds={clonedInSessionGroupIds}
        setClonedInSessionGroupIds={setClonedInSessionGroupIds}
      />
    </>
  )
}
