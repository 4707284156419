/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useEffect } from 'react'
import classNames from '../../common/helpers/classNames'

const statuses = [
  { id: 1, name: 'Exact', value: 'exact' },
  { id: 2, name: 'Partial', value: 'partial' },
  { id: 3, name: 'Ignore', value: 'ignore' },
]

export default function AllowPageMatchSelector({ form, endpointName, domain, index, data }) {
  const [selected, setSelected] = useState(statuses[2])

  const handleChange = (statusData) => {
    form.setFieldValue(`allow_urls[${domain}][${endpointName}][${index}].query.match`, statusData.value)

    if (statusData.value === 'exact') {
      const params = form.values.allow_urls[domain][endpointName][index].query.string.replace('?', '').split('&')

      let queries = {}

      for (let param of params) {
        const paramKey = param.split('=')[0]
        const paramValue = param.split('=')[1]

        queries[paramKey] = paramValue
      }

      form.setFieldValue(`allow_urls[${domain}][${endpointName}][${index}].query.object`, queries)
    }
  }

  useEffect(() => {
    if (selected.value !== data) setSelected(statuses.find((status) => status.value === data))
  }, [data, selected.value])

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block mt-4 text-sm font-medium text-gray-700">Query Match</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {statuses.map((status) => (
                  <Listbox.Option
                    key={status.id}
                    className={({ active }) =>
                      classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')
                    }
                    value={status}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{status.name}</span>

                        {selected ? (
                          <span
                            className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
